import React, { useState, useRef, useEffect } from 'react';
import { X, ZoomIn, ZoomOut, RotateCcw } from 'lucide-react';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

interface ChartModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  data: any;
  options: ChartOptions<'line'>;
}

export default function ChartModal({ isOpen, onClose, title, data, options }: ChartModalProps) {
  const [zoomLevel, setZoomLevel] = useState(1);
  const chartRef = useRef<ChartJS | null>(null);

  useEffect(() => {
    if (isOpen) {
      setZoomLevel(1);
      if (chartRef.current) {
        chartRef.current.resetZoom();
      }
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleZoomIn = () => {
    if (chartRef.current) {
      chartRef.current.zoom(1.1);
      setZoomLevel(prev => prev * 1.1);
    }
  };

  const handleZoomOut = () => {
    if (chartRef.current) {
      chartRef.current.zoom(0.9);
      setZoomLevel(prev => Math.max(1, prev * 0.9));
    }
  };

  const handleReset = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
      setZoomLevel(1);
    }
  };

  const fullscreenOptions: ChartOptions<'line'> = {
    ...options,
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    plugins: {
      ...options.plugins,
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'shift'
        },
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
          drag: {
            enabled: true,
            backgroundColor: 'rgba(0,255,0,0.1)',
            borderColor: 'rgba(0,255,0,0.3)',
            borderWidth: 1
          }
        }
      }
    },
    scales: {
      ...options.scales,
      x: {
        ...options.scales?.x,
        ticks: {
          ...options.scales?.x?.ticks,
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 20
        }
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="w-[95vw] h-[90vh] matrix-card p-6 rounded-lg relative">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-4">
            <h2 className="text-xl font-bold matrix-text">{title}</h2>
            <div className="flex items-center gap-2">
              <button
                onClick={handleZoomIn}
                className="matrix-button p-2 rounded-lg"
                title="Zoom In"
              >
                <ZoomIn className="w-5 h-5" />
              </button>
              <button
                onClick={handleZoomOut}
                className="matrix-button p-2 rounded-lg"
                title="Zoom Out"
                disabled={zoomLevel <= 1}
              >
                <ZoomOut className="w-5 h-5" />
              </button>
              <button
                onClick={handleReset}
                className="matrix-button p-2 rounded-lg"
                title="Reset Zoom"
                disabled={zoomLevel <= 1}
              >
                <RotateCcw className="w-5 h-5" />
              </button>
            </div>
          </div>
          <button
            onClick={onClose}
            className="matrix-text hover:text-matrix-lightGreen transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        
        <div className="h-[calc(100%-4rem)]">
          <Line 
            data={data}
            options={fullscreenOptions}
            ref={(element) => {
              if (element) {
                chartRef.current = element.chart;
              }
            }}
          />
        </div>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
          <p className="matrix-text text-sm opacity-80">
            Hold Shift + Click and drag to pan • Use mouse wheel or drag box to zoom
          </p>
        </div>
      </div>
    </div>
  );
}