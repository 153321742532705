import React, { useState, useMemo } from 'react';
import { Bitcoin, DollarSign, Wallet, Zap, TrendingUp, Network, Maximize2, Clock } from 'lucide-react';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { MiningResults } from '../types/mining';
import ChartModal from './ChartModal';
import { chartOptions, createChartData } from '../utils/charts';

interface ResultsChartProps {
  results: MiningResults[];
  networkData: {
    price: number;
    difficulty: number;
    timestamp: number;
  }[];
}

interface ChartCardProps {
  title: string;
  icon: any;
  children: React.ReactNode;
  onExpand: () => void;
}

// Helper function to format sats
const formatSats = (btc: number) => (btc * 1e8).toLocaleString(undefined, { maximumFractionDigits: 0 });

function ChartCard({ title, icon: Icon, children, onExpand }: ChartCardProps) {
  return (
    <div className="matrix-card p-6 rounded-lg space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Icon className="w-5 h-5 text-matrix-green" />
          <h3 className="text-lg font-medium matrix-text">{title}</h3>
        </div>
        <button
          onClick={onExpand}
          className="matrix-text hover:text-matrix-lightGreen transition-colors"
          title="View Fullscreen"
        >
          <Maximize2 className="w-5 h-5" />
        </button>
      </div>
      {children}
    </div>
  );
}

export default function ResultsChart({ results, networkData }: ResultsChartProps) {
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const labels = useMemo(() => 
    results.map(r => format(new Date(r.timestamp), 'MMM d, yyyy')),
    [results]
  );

  // Get the latest result for current day metrics
  const latestResult = results[results.length - 1];
  const latestNetworkData = networkData[networkData.length - 1];

  const chartData = useMemo(() => ({
    btc: createChartData(labels, results.map(r => r.btcMined), 'Daily BTC Mined', '#ffeb3b'),
    revenue: createChartData(labels, results.map(r => r.revenue), 'Daily Revenue', '#00ff00'),
    costs: createChartData(
      labels, 
      results.map(r => r.electricityCost + r.poolFees),
      'Daily Costs',
      '#ff073a'
    ),
    profit: createChartData(labels, results.map(r => r.netProfit), 'Net Profit', '#00ff00', true),
    price: createChartData(
      networkData.map(d => format(new Date(d.timestamp), 'MMM d, yyyy')),
      networkData.map(d => d.price),
      'BTC Price',
      '#f7931a'
    ),
    difficulty: createChartData(
      networkData.map(d => format(new Date(d.timestamp), 'MMM d, yyyy')),
      networkData.map(d => d.difficulty),
      'Network Difficulty',
      '#1ca9f2'
    ),
    timeToMine: createChartData(
      labels,
      results.map(r => 1 / r.btcMined),
      'Days to Mine 1 BTC',
      '#9c27b0'
    ),
    roi: createChartData(
      labels,
      results.map(r => r.cumulativeProfit),
      'Cumulative Profit (ROI)',
      '#00ff00',
      true
    )
  }), [labels, results, networkData]);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <ChartCard
          title="Bitcoin Price"
          icon={TrendingUp}
          onExpand={() => setActiveModal('price')}
        >
          <Line data={chartData.price} options={chartOptions.money} />
          <div className="text-sm matrix-text opacity-80 text-center mt-4">
            {latestNetworkData && (
              <p>Current Price: ${latestNetworkData.price.toLocaleString(undefined, { maximumFractionDigits: 2 })} USD</p>
            )}
          </div>
        </ChartCard>
        
        <ChartCard
          title="Network Difficulty"
          icon={Network}
          onExpand={() => setActiveModal('difficulty')}
        >
          <Line data={chartData.difficulty} options={chartOptions.difficulty} />
          <div className="text-sm matrix-text opacity-80 text-center mt-4">
            {latestNetworkData && (
              <div>
                <p>Current Difficulty: {(latestNetworkData.difficulty / 1e12).toFixed(2)}T</p>
                <p>Network Hashrate: {(latestNetworkData.hashrate / 1e18).toFixed(2)} EH/s</p>
              </div>
            )}
          </div>
        </ChartCard>
        
        <ChartCard
          title="Daily Bitcoin Mined"
          icon={Bitcoin}
          onExpand={() => setActiveModal('btc')}
        >
          <Line data={chartData.btc} options={chartOptions.btc} />
          <div className="text-sm matrix-text opacity-80 text-center mt-4">
            {latestResult && (
              <div>
                <p>Daily: {latestResult.btcMined.toFixed(8)} BTC</p>
                <p>{formatSats(latestResult.btcMined)} sats</p>
                <p>Monthly: {(latestResult.btcMined * 30).toFixed(8)} BTC</p>
                <p>Yearly: {(latestResult.btcMined * 365).toFixed(8)} BTC</p>
              </div>
            )}
          </div>
        </ChartCard>
        
        <ChartCard
          title="Time to Mine One Bitcoin"
          icon={Clock}
          onExpand={() => setActiveModal('timeToMine')}
        >
          <Line data={chartData.timeToMine} options={chartOptions.timeToMine} />
          <div className="text-sm matrix-text opacity-80 text-center mt-4">
            {latestResult && (
              <div>
                <p>Current Time to Mine 1 BTC: {(1 / latestResult.btcMined).toFixed(1)} days</p>
                <p>({(1 / latestResult.btcMined / 365).toFixed(2)} years)</p>
                <p>Time to Mine 0.1 BTC: {(0.1 / latestResult.btcMined).toFixed(1)} days</p>
                <p>Time to Mine 0.01 BTC: {(0.01 / latestResult.btcMined).toFixed(1)} days</p>
              </div>
            )}
          </div>
        </ChartCard>
        
        <ChartCard
          title="Daily Revenue"
          icon={DollarSign}
          onExpand={() => setActiveModal('revenue')}
        >
          <Line data={chartData.revenue} options={chartOptions.money} />
          <div className="text-sm matrix-text opacity-80 text-center mt-4">
            {latestResult && (
              <div>
                <p>Daily: ${latestResult.revenue.toFixed(2)} USD</p>
                <p>{latestResult.btcMined.toFixed(8)} BTC ({formatSats(latestResult.btcMined)} sats)</p>
                <p>Monthly: ${(latestResult.revenue * 30).toFixed(2)} USD</p>
                <p>Yearly: ${(latestResult.revenue * 365).toFixed(2)} USD</p>
              </div>
            )}
          </div>
        </ChartCard>
        
        <ChartCard
          title="Daily Costs"
          icon={Zap}
          onExpand={() => setActiveModal('costs')}
        >
          <Line data={chartData.costs} options={chartOptions.money} />
          <div className="text-sm matrix-text opacity-80 text-center mt-4">
            {latestResult && (
              <div>
                <p>Daily Total: ${(latestResult.electricityCost + latestResult.poolFees).toFixed(2)} USD</p>
                <p>Power: ${latestResult.electricityCost.toFixed(2)} ({latestResult.electricityCostInBTC.toFixed(8)} BTC)</p>
                <p>Pool Fees: ${latestResult.poolFees.toFixed(2)} ({latestResult.poolFeesInBTC.toFixed(8)} BTC)</p>
                <p>Monthly: ${((latestResult.electricityCost + latestResult.poolFees) * 30).toFixed(2)} USD</p>
              </div>
            )}
          </div>
        </ChartCard>
        
        <ChartCard
          title="Daily Net Profit"
          icon={Wallet}
          onExpand={() => setActiveModal('profit')}
        >
          <Line data={chartData.profit} options={chartOptions.money} />
          <div className="text-sm matrix-text opacity-80 text-center mt-4">
            {latestResult && (
              <div>
                <p>Daily: ${latestResult.netProfit.toFixed(2)} USD</p>
                <p>{latestResult.netProfitInBTC.toFixed(8)} BTC ({formatSats(latestResult.netProfitInBTC)} sats)</p>
                <p>Monthly: ${(latestResult.netProfit * 30).toFixed(2)} USD</p>
                <p>Yearly: ${(latestResult.netProfit * 365).toFixed(2)} USD</p>
              </div>
            )}
          </div>
        </ChartCard>

        <ChartCard
          title="Return on Investment (ROI)"
          icon={Wallet}
          onExpand={() => setActiveModal('roi')}
        >
          <Line data={chartData.roi} options={chartOptions.money} />
          <div className="text-sm matrix-text opacity-80 text-center mt-4">
            {latestResult && (
              <div>
                {latestResult.daysToROI !== null ? (
                  <>
                    <p>ROI achieved after {latestResult.daysToROI} days</p>
                    <p>({(latestResult.daysToROI / 365).toFixed(2)} years)</p>
                    <p>Current ROI: {((latestResult.cumulativeProfit / -results[0].cumulativeProfit) * 100).toFixed(2)}%</p>
                  </>
                ) : (
                  <p>ROI not yet achieved</p>
                )}
                <p>Total Profit: ${latestResult.cumulativeProfit.toFixed(2)} USD</p>
              </div>
            )}
          </div>
        </ChartCard>
      </div>

      {activeModal && (
        <ChartModal
          isOpen={true}
          onClose={() => setActiveModal(null)}
          title={chartData[activeModal as keyof typeof chartData].datasets[0].label || ''}
          data={chartData[activeModal as keyof typeof chartData]}
          options={chartOptions[activeModal as keyof typeof chartOptions] || chartOptions.money}
        />
      )}
    </>
  );
}