import { NetworkData } from '../types/mining';

const COINGECKO_API = 'https://api.coingecko.com/api/v3';
const BLOCKCHAIN_INFO_API = 'https://api.blockchain.info';

const RETRY_DELAY = 2000;
const MAX_RETRIES = 3;
const MS_PER_DAY = 86400000;

export class ApiError extends Error {
  constructor(message: string, public status?: number) {
    super(message);
    this.name = 'ApiError';
  }
}

async function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function fetchWithRetry(url: string, retries = MAX_RETRIES): Promise<Response> {
  for (let i = 0; i <= retries; i++) {
    try {
      const response = await fetch(url);
      
      if (response.status === 429) {
        if (i < retries) {
          const retryAfter = response.headers.get('Retry-After');
          const delay = retryAfter ? parseInt(retryAfter) * 1000 : RETRY_DELAY * (i + 1);
          await sleep(delay);
          continue;
        }
        throw new ApiError('API rate limit exceeded. Please wait a few minutes before trying again.', 429);
      }
      
      if (!response.ok) {
        throw new ApiError(`API request failed with status: ${response.status}`, response.status);
      }
      
      return response;
    } catch (error) {
      if (error instanceof ApiError) throw error;
      if (i === retries) throw error;
      await sleep(RETRY_DELAY * (i + 1));
    }
  }
  throw new ApiError('Maximum retry attempts exceeded');
}

export async function getCurrentBTCPrice(): Promise<number> {
  try {
    const response = await fetchWithRetry(
      `${COINGECKO_API}/simple/price?ids=bitcoin&vs_currencies=usd`
    );
    const data = await response.json();
    return data.bitcoin.usd;
  } catch (error) {
    console.error('Failed to fetch current BTC price:', error);
    throw new ApiError('Failed to fetch current Bitcoin price');
  }
}

function interpolateValue(point1: [number, number], point2: [number, number], timestamp: number): number {
  const [t1, v1] = point1;
  const [t2, v2] = point2;
  
  if (timestamp <= t1) return v1;
  if (timestamp >= t2) return v2;
  
  const progress = (timestamp - t1) / (t2 - t1);
  return v1 + (v2 - v1) * progress;
}

function findSurroundingPoints(data: [number, number][], timestamp: number): [[number, number], [number, number]] {
  let before = data[0];
  let after = data[data.length - 1];
  
  for (let i = 0; i < data.length - 1; i++) {
    if (data[i][0] <= timestamp && data[i + 1][0] > timestamp) {
      before = data[i];
      after = data[i + 1];
      break;
    }
  }
  
  return [before, after];
}

async function fetchPriceData(startDate: Date, endDate: Date): Promise<[number, number][]> {
  try {
    const response = await fetchWithRetry(
      `${BLOCKCHAIN_INFO_API}/charts/market-price?timespan=all&format=json&cors=true`
    );
    const data = await response.json();
    
    if (data.values?.length > 0 && Array.isArray(data.values)) {
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      
      const prices = data.values
        .filter((v: any) => {
          const timestamp = v.x * 1000;
          return timestamp >= startTime - MS_PER_DAY && timestamp <= endTime + MS_PER_DAY;
        })
        .map((v: any) => [v.x * 1000, v.y]);
      
      return prices;
    }
    throw new Error('Invalid price data format from Blockchain.info');
  } catch (error) {
    throw new ApiError(
      `Failed to fetch price data: ${error instanceof Error ? error.message : 'Unknown error'}`
    );
  }
}

async function fetchDifficultyData(startDate: Date, endDate: Date): Promise<[number, number][]> {
  try {
    const response = await fetchWithRetry(
      `${BLOCKCHAIN_INFO_API}/charts/difficulty?timespan=all&format=json&cors=true`
    );
    const data = await response.json();
    
    if (data.values?.length > 0 && Array.isArray(data.values)) {
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      
      const difficulties = data.values
        .filter((v: any) => {
          const timestamp = v.x * 1000;
          return timestamp >= startTime - MS_PER_DAY && timestamp <= endTime + MS_PER_DAY;
        })
        .map((v: any) => [v.x * 1000, v.y]); // Keep original difficulty value
      
      return difficulties;
    }
    throw new Error('Invalid difficulty data format');
  } catch (error) {
    throw new ApiError(
      `Failed to fetch difficulty data: ${error instanceof Error ? error.message : 'Unknown error'}`
    );
  }
}

export async function fetchHistoricalData(startDate: Date, endDate: Date): Promise<NetworkData[]> {
  try {
    // Validate dates
    if (endDate > new Date()) {
      throw new ApiError('End date cannot be in the future');
    }
    if (endDate <= startDate) {
      throw new ApiError('End date must be after start date');
    }

    // Fetch both datasets concurrently
    const [priceData, difficultyData] = await Promise.all([
      fetchPriceData(startDate, endDate),
      fetchDifficultyData(startDate, endDate)
    ]);

    // Generate daily data points
    const dailyData: NetworkData[] = [];
    let currentDate = new Date(startDate);
    currentDate.setUTCHours(0, 0, 0, 0);
    const endTime = endDate.getTime();

    while (currentDate.getTime() <= endTime) {
      const timestamp = currentDate.getTime();

      // Find surrounding data points and interpolate values
      const [beforePrice, afterPrice] = findSurroundingPoints(priceData, timestamp);
      const [beforeDiff, afterDiff] = findSurroundingPoints(difficultyData, timestamp);

      const price = interpolateValue(beforePrice, afterPrice, timestamp);
      const difficulty = interpolateValue(beforeDiff, afterDiff, timestamp);

      // Calculate network hashrate in TH/s
      const hashrate = difficulty * Math.pow(2, 32) / 600;

      dailyData.push({
        difficulty,
        hashrate,
        price,
        timestamp
      });

      // Move to next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dailyData;
  } catch (error) {
    if (error instanceof ApiError) throw error;
    throw new ApiError(
      error instanceof Error 
        ? `Failed to fetch mining data: ${error.message}`
        : 'An unexpected error occurred while fetching mining data'
    );
  }
}

export function cacheData(key: string, data: any): void {
  try {
    const cacheEntry = {
      timestamp: Date.now(),
      data
    };
    localStorage.setItem(key, JSON.stringify(cacheEntry));
  } catch (error) {
    console.warn('Failed to cache data:', error);
  }
}

export function getCachedData(key: string): any | null {
  try {
    const cached = localStorage.getItem(key);
    if (!cached) return null;

    const { timestamp, data } = JSON.parse(cached);
    // Cache expires after 1 hour
    if (Date.now() - timestamp > 3600000) {
      localStorage.removeItem(key);
      return null;
    }

    return data;
  } catch (error) {
    console.warn('Failed to retrieve cached data:', error);
    localStorage.removeItem(key);
    return null;
  }
}