import { utils, writeFile } from 'xlsx';
import { MiningResults, NetworkData } from '../types/mining';
import { format } from 'date-fns';

interface ExportData {
  results: MiningResults[];
  networkData: NetworkData[];
}

export function exportToExcel({ results, networkData }: ExportData) {
  // Get current BTC price from the latest network data
  const currentPrice = networkData[networkData.length - 1]?.price || 0;
  const equipmentCost = -results[0]?.cumulativeProfit || 0; // Get total equipment cost from first cumulative profit

  // Create price input section
  const priceInput = [
    [{ v: 'BTC Price Calculator', t: 's' }],
    [{ v: 'Current BTC Price (USD)', t: 's' }, { v: currentPrice, t: 'n', w: currentPrice.toFixed(2) }],
    [{ v: 'Custom BTC Price (USD)', t: 's' }, { v: currentPrice, t: 'n', w: currentPrice.toFixed(2) }],
    [{ v: 'Total Equipment Cost (USD)', t: 's' }, { v: equipmentCost, t: 'n', w: equipmentCost.toFixed(2) }],
    [{ v: 'Instructions:', t: 's' }],
    [{ v: '1. Enter your custom BTC price in cell B3', t: 's' }],
    [{ v: '2. All BTC values below will update automatically', t: 's' }],
    [],  // Empty row for spacing
  ];

  // Mining Results Sheet - BTC Focus
  const miningData = results.map(r => ({
    Date: format(new Date(r.timestamp), 'yyyy-MM-dd'),
    'BTC Mined': r.btcMined,
    'BTC Revenue Rate (BTC/day)': r.btcMined,
    'Electricity Cost (BTC)': r.electricityCostInBTC,
    'Pool Fees (BTC)': r.poolFeesInBTC,
    'Net Profit (BTC)': r.netProfitInBTC,
    'Cumulative Profit (BTC)': r.cumulativeProfit / networkData.find(d => d.timestamp === r.timestamp)?.price!,
    'BTC Price (USD)': networkData.find(d => d.timestamp === r.timestamp)?.price,
    'Revenue (USD)': { v: r.revenue, f: `B3*B${9 + priceInput.length}` },
    'Electricity Cost (USD)': r.electricityCost,
    'Pool Fees (USD)': r.poolFees,
    'Net Profit (USD)': r.netProfit,
    'Cumulative Profit (USD)': r.cumulativeProfit,
    'Days to ROI': r.daysToROI !== null ? r.daysToROI : 'Not achieved',
    'Solo Mining Odds (1 in X)': r.soloMiningOdds.perBlock,
    'Daily Solo Mining Odds (1 in X)': r.soloMiningOdds.perDay
  }));

  // Network Data Sheet
  const networkDataFormatted = networkData.map(d => ({
    Date: format(new Date(d.timestamp), 'yyyy-MM-dd'),
    'Network Difficulty': d.difficulty,
    'Network Hashrate (TH/s)': d.hashrate / 1e12,
    'BTC Price (USD)': d.price,
    'Hashrate Share (%)': results[0] ? (results[0].btcMined * 144) / (50 * Math.pow(2, Math.floor((d.timestamp - 1231006505000) / (210000 * 10 * 60 * 1000)))) * 100 : 0
  }));

  // Totals Sheet with Summary Statistics
  const totalBTC = results.reduce((sum, r) => sum + r.btcMined, 0);
  const avgPrice = networkData.reduce((sum, d) => sum + d.price, 0) / networkData.length;
  const roiDay = results[0]?.daysToROI;
  const totalDays = results.length;
  
  const totals = [
    ...priceInput,
    [{ v: 'Mining Summary Statistics', t: 's' }],
    [
      { v: 'Total Days Analyzed', t: 's' },
      { v: totalDays, t: 'n' }
    ],
    [
      { v: 'Total BTC Mined', t: 's' },
      { v: totalBTC, t: 'n', w: totalBTC.toFixed(8) }
    ],
    [
      { v: 'Average Daily BTC', t: 's' },
      { v: totalBTC / totalDays, t: 'n', w: (totalBTC / totalDays).toFixed(8) }
    ],
    [
      { v: 'Total Electricity Cost (BTC)', t: 's' },
      { v: results.reduce((sum, r) => sum + r.electricityCostInBTC, 0), t: 'n', w: results.reduce((sum, r) => sum + r.electricityCostInBTC, 0).toFixed(8) }
    ],
    [
      { v: 'Total Pool Fees (BTC)', t: 's' },
      { v: results.reduce((sum, r) => sum + r.poolFeesInBTC, 0), t: 'n', w: results.reduce((sum, r) => sum + r.poolFeesInBTC, 0).toFixed(8) }
    ],
    [
      { v: 'Total Net Profit (BTC)', t: 's' },
      { v: results.reduce((sum, r) => sum + r.netProfitInBTC, 0), t: 'n', w: results.reduce((sum, r) => sum + r.netProfitInBTC, 0).toFixed(8) }
    ],
    [
      { v: 'Average BTC Price (USD)', t: 's' },
      { v: avgPrice, t: 'n', w: avgPrice.toFixed(2) }
    ],
    [
      { v: 'Total Revenue (USD)', t: 's' },
      { v: results.reduce((sum, r) => sum + r.revenue, 0), t: 'n', w: results.reduce((sum, r) => sum + r.revenue, 0).toFixed(2) }
    ],
    [
      { v: 'Total Costs (USD)', t: 's' },
      { v: results.reduce((sum, r) => sum + r.electricityCost + r.poolFees, 0), t: 'n', w: results.reduce((sum, r) => sum + r.electricityCost + r.poolFees, 0).toFixed(2) }
    ],
    [
      { v: 'Total Net Profit (USD)', t: 's' },
      { v: results.reduce((sum, r) => sum + r.netProfit, 0), t: 'n', w: results.reduce((sum, r) => sum + r.netProfit, 0).toFixed(2) }
    ],
    [
      { v: 'Days to ROI', t: 's' },
      { v: roiDay !== null ? roiDay : 'Not achieved', t: roiDay !== null ? 'n' : 's' }
    ],
    [
      { v: 'ROI Percentage', t: 's' },
      { 
        v: roiDay !== null ? 
          ((results[roiDay].cumulativeProfit + equipmentCost) / equipmentCost * 100) : 
          'Not achieved',
        t: roiDay !== null ? 'n' : 's',
        w: roiDay !== null ? 
          ((results[roiDay].cumulativeProfit + equipmentCost) / equipmentCost * 100).toFixed(2) + '%' : 
          'Not achieved'
      }
    ],
    [],
    [{ v: 'Solo Mining Statistics', t: 's' }],
    [
      { v: 'Current Block Odds', t: 's' },
      { v: `1 in ${results[results.length - 1].soloMiningOdds.perBlock.toLocaleString()}`, t: 's' }
    ],
    [
      { v: 'Current Daily Odds', t: 's' },
      { v: `1 in ${results[results.length - 1].soloMiningOdds.perDay.toLocaleString()}`, t: 's' }
    ]
  ];

  // Create workbook and add sheets
  const wb = utils.book_new();
  
  // Add sheets with custom column widths
  const ws1 = utils.json_to_sheet(miningData);
  const ws2 = utils.json_to_sheet(networkDataFormatted);
  const ws3 = utils.json_to_sheet(totals);
  
  // Add some styling to the price input section
  ws3['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }  // Merge the title cells
  ];
  
  // Set column widths
  ws1['!cols'] = [
    { wch: 12 }, // Date
    { wch: 15 }, // BTC Mined
    { wch: 20 }, // BTC Revenue Rate
    { wch: 18 }, // Electricity Cost BTC
    { wch: 15 }, // Pool Fees BTC
    { wch: 15 }, // Net Profit BTC
    { wch: 20 }, // Cumulative Profit BTC
    { wch: 15 }, // BTC Price
    { wch: 15 }, // Revenue USD
    { wch: 18 }, // Electricity Cost USD
    { wch: 15 }, // Pool Fees USD
    { wch: 15 }, // Net Profit USD
    { wch: 20 }, // Cumulative Profit USD
    { wch: 15 }, // Days to ROI
    { wch: 20 }, // Solo Mining Odds
    { wch: 20 }  // Daily Solo Mining Odds
  ];

  ws2['!cols'] = [
    { wch: 12 }, // Date
    { wch: 20 }, // Network Difficulty
    { wch: 20 }, // Network Hashrate
    { wch: 15 }, // BTC Price
    { wch: 18 }  // Hashrate Share
  ];

  ws3['!cols'] = [
    { wch: 25 }, // Labels
    { wch: 20 }  // Values
  ];
  
  utils.book_append_sheet(wb, ws1, 'Mining Results');
  utils.book_append_sheet(wb, ws2, 'Network Data');
  utils.book_append_sheet(wb, ws3, 'Summary');

  // Generate filename with current date
  const filename = `bitcoin-mining-analysis-${format(new Date(), 'yyyy-MM-dd')}.xlsx`;
  
  // Write file
  writeFile(wb, filename);
}