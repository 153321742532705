import React from 'react';
import { Bitcoin } from 'lucide-react';

interface HeaderProps {
  onNavigate: (page: 'calculator' | 'privacy' | 'terms') => void;
}

export default function Header({ onNavigate }: HeaderProps) {
  return (
    <header className="relative bg-black border-b border-matrix-green/20">
      <div className="h-20">
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
          <div className="flex items-center justify-between h-full">
            <button
              onClick={() => onNavigate('calculator')}
              className="flex items-center gap-2 matrix-text hover:text-matrix-lightGreen transition-colors"
            >
              <Bitcoin className="w-8 h-8 text-matrix-green" />
              <span className="matrix-title text-xl">BTC Mining Calculator</span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}