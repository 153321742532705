import React from 'react';
import { DollarSign } from 'lucide-react';

interface AdBannerProps {
  adSlot: string;
  className?: string;
}

export default function AdBanner({ adSlot, className = '' }: AdBannerProps) {
  return (
    <div className={`matrix-card p-4 rounded-lg ${className}`}>
      <div className="flex items-center gap-2 mb-2">
        <DollarSign className="w-4 h-4 text-matrix-green" />
        <span className="text-sm matrix-text opacity-70">Sponsored</span>
      </div>
      <div className="bg-black/50 rounded overflow-hidden min-h-[90px]">
        {/* Desktop version */}
        <div className="hidden md:block">
          <div id="frame">
            <iframe 
              data-aa={adSlot}
              src={`//ad.a-ads.com/${adSlot}?size=728x90`}
              style={{
                width: '728px',
                height: '90px',
                border: '0px',
                padding: '0',
                overflow: 'hidden',
                backgroundColor: 'transparent'
              }}
              title="Anonymous Ads"
            />
            <a 
              style={{
                display: 'block',
                textAlign: 'right',
                fontSize: '12px'
              }}
              className="matrix-text opacity-50 hover:opacity-100 transition-opacity"
              href={`https://a-ads.com/campaigns/new?source_id=${adSlot}&source_type=ad_unit&partner=${adSlot}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Advertise here
            </a>
          </div>
        </div>
        {/* Mobile version - 320x50 format */}
        <div className="md:hidden">
          <div id="frame-mobile">
            <iframe 
              data-aa={adSlot}
              src={`//ad.a-ads.com/${adSlot}?size=320x50`}
              style={{
                width: '320px',
                height: '50px',
                border: '0px',
                padding: '0',
                overflow: 'hidden',
                backgroundColor: 'transparent'
              }}
              title="Anonymous Ads Mobile"
            />
            <a 
              style={{
                display: 'block',
                textAlign: 'right',
                fontSize: '12px'
              }}
              className="matrix-text opacity-50 hover:opacity-100 transition-opacity"
              href={`https://a-ads.com/campaigns/new?source_id=${adSlot}&source_type=ad_unit&partner=${adSlot}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Advertise here
            </a>
          </div>
        </div>
        {/* Fallback for no JavaScript */}
        <noscript>
          <iframe 
            src={`//ad.a-ads.com/${adSlot}?size=728x90&no_js=1`}
            style={{
              width: '728px',
              height: '90px',
              border: '0px',
              padding: '0',
              overflow: 'hidden',
              backgroundColor: 'transparent'
            }}
            title="Anonymous Ads (No JavaScript)"
          />
        </noscript>
      </div>
    </div>
  );
}