import React, { useState } from 'react';
import { Heart, MessageSquare } from 'lucide-react';
import BTCModal from './BTCModal';

interface FooterProps {
  onNavigate: (page: 'calculator' | 'privacy' | 'terms') => void;
}

export default function Footer({ onNavigate }: FooterProps) {
  const currentYear = new Date().getFullYear();
  const btcAddress = "bc1qffcan0z6xfv0nud7q5cdsl7uuhjdeqrz0dgxf0";
  const [modalType, setModalType] = useState<'feature' | 'support' | null>(null);

  return (
    <>
      <footer className="matrix-card border-t border-matrix-green/20 py-8 mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <h3 className="matrix-text font-semibold">About</h3>
              <div className="space-y-2">
                <p className="matrix-text text-sm opacity-80">
                  A Bitcoin mining calculator with historical data analysis
                  and advanced profitability metrics.
                </p>
                <p className="matrix-text text-sm opacity-80">
                  Coming Soon: Walk-forward projections for future mining profitability analysis. 
                  This feature is currently in development - please consider supporting its 
                  development through donations.
                </p>
              </div>
            </div>
            
            <div className="space-y-4">
              <h3 className="matrix-text font-semibold">Feature Requests</h3>
              <p className="matrix-text text-sm opacity-80">
                Want a new feature? Send a BTC transaction with your request in the OP_RETURN data.
                We'll prioritize development based on transaction value.
              </p>
              <div className="flex flex-col gap-2">
                <button
                  onClick={() => setModalType('feature')}
                  className="matrix-text text-sm opacity-80 hover:opacity-100 transition-opacity inline-flex items-center gap-1"
                >
                  <MessageSquare className="w-4 h-4" />
                  Send Feature Request
                </button>
                <button
                  onClick={() => setModalType('support')}
                  className="matrix-text text-sm opacity-80 hover:opacity-100 transition-opacity inline-flex items-center gap-1"
                >
                  <Heart className="w-4 h-4" />
                  Support Development
                </button>
              </div>
            </div>
          </div>
          
          <div className="mt-8 pt-8 border-t border-matrix-green/20 flex flex-col sm:flex-row justify-between items-center gap-4">
            <p className="matrix-text text-sm opacity-80">
              © {currentYear} BTC Mining Calculator. All rights reserved.
            </p>
            <div className="flex gap-4">
              <button
                onClick={() => onNavigate('privacy')}
                className="matrix-text text-sm opacity-80 hover:opacity-100 transition-opacity"
              >
                Privacy Policy
              </button>
              <button
                onClick={() => onNavigate('terms')}
                className="matrix-text text-sm opacity-80 hover:opacity-100 transition-opacity"
              >
                Terms of Service
              </button>
            </div>
          </div>
        </div>
      </footer>

      <BTCModal
        isOpen={modalType !== null}
        onClose={() => setModalType(null)}
        address={btcAddress}
        type={modalType || 'support'}
      />
    </>
  );
}