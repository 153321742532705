import React, { useState } from 'react';
import { Settings, Calculator, Info } from 'lucide-react';
import { MiningInputs, HASH_RATE_MULTIPLIERS } from '../types/mining';

interface InputFormProps {
  onSubmit: (inputs: MiningInputs) => void;
  isLoading: boolean;
}

export default function InputForm({ onSubmit, isLoading }: InputFormProps) {
  const [inputs, setInputs] = useState<MiningInputs>({
    hashRate: 100,
    hashRateUnit: 'TH/s',
    powerConsumption: 3000,
    electricityCost: 0.06,
    poolFee: 2,
    startDate: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    equipmentCost: 400,
    numberOfMiners: 1
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (inputs.endDate <= inputs.startDate) {
      alert('End date must be after start date');
      return;
    }

    if (inputs.hashRate <= 0) {
      alert('Hash rate must be greater than 0');
      return;
    }

    if (inputs.powerConsumption <= 0) {
      alert('Power consumption must be greater than 0');
      return;
    }

    if (inputs.equipmentCost < 0) {
      alert('Equipment cost cannot be negative');
      return;
    }

    if (inputs.numberOfMiners <= 0) {
      alert('Number of miners must be greater than 0');
      return;
    }

    onSubmit(inputs);
  };

  return (
    <form onSubmit={handleSubmit} className="matrix-card p-6 rounded-lg" aria-label="Mining Calculator Form">
      <div className="flex items-center gap-2 mb-6">
        <Settings className="w-6 h-6 text-matrix-green" aria-hidden="true" />
        <h2 className="text-xl font-semibold matrix-text">Mining Parameters</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Number of Miners */}
        <div className="form-group">
          <label htmlFor="numberOfMiners" className="block text-sm font-medium matrix-text mb-1">
            Number of Miners
          </label>
          <div className="relative">
            <input
              id="numberOfMiners"
              type="number"
              value={inputs.numberOfMiners}
              onChange={(e) => setInputs({ ...inputs, numberOfMiners: Math.max(1, parseInt(e.target.value) || 1) })}
              className="mt-1 block w-full rounded-md matrix-input focus:outline-none"
              min="1"
              step="1"
              required
              aria-describedby="numberOfMinersHelp"
            />
          </div>
          <p id="numberOfMinersHelp" className="mt-1 text-xs matrix-text opacity-60">
            All calculations will be multiplied by this number
          </p>
        </div>

        <div className="form-group">
          <label htmlFor="hashRate" className="block text-sm font-medium matrix-text mb-1">
            Hash Rate (per unit)
          </label>
          <div className="relative flex gap-2">
            <input
              id="hashRate"
              type="number"
              value={inputs.hashRate}
              onChange={(e) => setInputs({ ...inputs, hashRate: Number(e.target.value) })}
              className="mt-1 block w-full rounded-md matrix-input focus:outline-none"
              min="0.000001"
              step="0.000001"
              required
              aria-describedby="hashRateHelp"
            />
            <select
              value={inputs.hashRateUnit}
              onChange={(e) => setInputs({ ...inputs, hashRateUnit: e.target.value as keyof typeof HASH_RATE_MULTIPLIERS })}
              className="mt-1 matrix-input rounded-md min-w-[100px] focus:outline-none"
              aria-label="Hash Rate Unit"
            >
              {Object.keys(HASH_RATE_MULTIPLIERS).map(unit => (
                <option key={unit} value={unit} className="bg-black">{unit}</option>
              ))}
            </select>
          </div>
          <p id="hashRateHelp" className="mt-1 text-xs matrix-text opacity-60">
            Hash rate for a single mining unit
          </p>
        </div>

        <div className="form-group">
          <label htmlFor="equipmentCost" className="block text-sm font-medium matrix-text mb-1">
            Equipment Cost (USD per unit)
          </label>
          <div className="relative">
            <input
              id="equipmentCost"
              type="number"
              value={inputs.equipmentCost}
              onChange={(e) => setInputs({ ...inputs, equipmentCost: Number(e.target.value) })}
              className="mt-1 block w-full rounded-md matrix-input focus:outline-none"
              min="0"
              step="0.01"
              required
              aria-describedby="equipmentCostHelp"
            />
          </div>
          <p id="equipmentCostHelp" className="mt-1 text-xs matrix-text opacity-60">
            Cost for a single mining unit
          </p>
        </div>

        <div className="form-group">
          <label htmlFor="powerConsumption" className="block text-sm font-medium matrix-text mb-1">
            Power Consumption (W per unit)
          </label>
          <div className="relative">
            <input
              id="powerConsumption"
              type="number"
              value={inputs.powerConsumption}
              onChange={(e) => setInputs({ ...inputs, powerConsumption: Number(e.target.value) })}
              className="mt-1 block w-full rounded-md matrix-input focus:outline-none"
              min="1"
              required
              aria-describedby="powerHelp"
            />
          </div>
          <p id="powerHelp" className="mt-1 text-xs matrix-text opacity-60">
            Power consumption for a single mining unit
          </p>
        </div>

        <div className="form-group">
          <label htmlFor="electricityCost" className="block text-sm font-medium matrix-text mb-1">
            Electricity Cost ($/kWh)
          </label>
          <div className="relative">
            <input
              id="electricityCost"
              type="number"
              value={inputs.electricityCost}
              onChange={(e) => setInputs({ ...inputs, electricityCost: Number(e.target.value) })}
              className="mt-1 block w-full rounded-md matrix-input focus:outline-none"
              min="0"
              step="0.00001"
              required
              aria-describedby="electricityHelp"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="poolFee" className="block text-sm font-medium matrix-text mb-1">
            Pool Fee (%)
          </label>
          <div className="relative">
            <input
              id="poolFee"
              type="number"
              value={inputs.poolFee}
              onChange={(e) => setInputs({ ...inputs, poolFee: Number(e.target.value) })}
              className="mt-1 block w-full rounded-md matrix-input focus:outline-none"
              min="0"
              max="100"
              step="0.01"
              required
              aria-describedby="poolFeeHelp"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="startDate" className="block text-sm font-medium matrix-text mb-1">
            Start Date
          </label>
          <div className="relative">
            <input
              id="startDate"
              type="date"
              value={inputs.startDate.toISOString().split('T')[0]}
              onChange={(e) => setInputs({ ...inputs, startDate: new Date(e.target.value) })}
              className="mt-1 block w-full rounded-md matrix-input focus:outline-none"
              max={inputs.endDate.toISOString().split('T')[0]}
              required
              aria-describedby="startDateHelp"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="endDate" className="block text-sm font-medium matrix-text mb-1">
            End Date
          </label>
          <div className="relative">
            <input
              id="endDate"
              type="date"
              value={inputs.endDate.toISOString().split('T')[0]}
              onChange={(e) => setInputs({ ...inputs, endDate: new Date(e.target.value) })}
              className="mt-1 block w-full rounded-md matrix-input focus:outline-none"
              min={inputs.startDate.toISOString().split('T')[0]}
              max={new Date().toISOString().split('T')[0]}
              required
              aria-describedby="endDateHelp"
            />
          </div>
        </div>
      </div>

      <div className="mt-6 matrix-card bg-opacity-50 p-4 rounded-md" role="note">
        <div className="flex items-center gap-2">
          <Info className="w-5 h-5 text-matrix-green" aria-hidden="true" />
          <p className="matrix-text text-sm">
            Enter parameters for a single mining unit and specify the number of miners. All calculations will be automatically scaled.
          </p>
        </div>
      </div>

      <div className="mt-6 space-y-4">
        {isLoading && (
          <div className="w-full h-2 bg-black/50 rounded-full overflow-hidden" role="progressbar" aria-label="Calculating results">
            <div className="h-full bg-matrix-green/50 animate-[loading_1s_ease-in-out_infinite]" />
          </div>
        )}

        <button
          type="submit"
          disabled={isLoading}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 rounded-md matrix-button disabled:opacity-50 disabled:cursor-not-allowed"
          aria-busy={isLoading}
        >
          <Calculator className="w-5 h-5" aria-hidden="true" />
          <span>{isLoading ? 'Calculating...' : 'Calculate Profitability'}</span>
        </button>
      </div>
    </form>
  );
}