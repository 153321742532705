import { HASH_RATE_MULTIPLIERS } from '../types/mining';

// Bitcoin halving dates (timestamps in milliseconds)
export const HALVING_DATES = [
  new Date('2009-01-03').getTime(), // Genesis block: 50 BTC
  new Date('2012-11-28').getTime(), // Block 210000: 50 BTC to 25 BTC
  new Date('2016-07-09').getTime(), // Block 420000: 25 BTC to 12.5 BTC
  new Date('2020-05-11').getTime(), // Block 630000: 12.5 BTC to 6.25 BTC
  new Date('2024-04-20').getTime(), // Block 840000: 6.25 BTC to 3.125 BTC (estimated)
];

// Calculate block reward based on timestamp
export function getBlockReward(timestamp: number): number {
  if (!timestamp || isNaN(timestamp) || timestamp < HALVING_DATES[0]) {
    console.warn('Invalid timestamp for block reward calculation, using current reward');
    return 6.25; // Current reward as fallback
  }

  let reward = 50; // Initial block reward
  let halvings = 0;
  
  for (let i = 1; i < HALVING_DATES.length; i++) {
    if (timestamp >= HALVING_DATES[i]) {
      halvings++;
    }
  }
  
  return reward / Math.pow(2, halvings);
}

// Calculate solo mining odds (1 in X chance to solve a block)
export function calculateSoloMiningOdds(
  hashrate: number,
  hashRateUnit: keyof typeof HASH_RATE_MULTIPLIERS,
  networkDifficulty: number,
  numberOfMiners: number = 1
): { perBlock: number; perDay: number } {
  try {
    // Convert hashrate to H/s, accounting for number of miners
    const hashrateInTHs = hashrate * HASH_RATE_MULTIPLIERS[hashRateUnit] * numberOfMiners;
    const hashrateHs = hashrateInTHs * 1e12;
    
    // Calculate probability per block
    // The odds are: (difficulty * 2^32) / (hashrate * 600)
    // 600 seconds is the target block time
    const oddsPerBlock = (networkDifficulty * Math.pow(2, 32)) / (hashrateHs * 600);
    
    // Calculate probability per day
    // There are 144 blocks per day on average (24 hours * 60 minutes / 10 minutes per block)
    // The probability of finding at least one block in 144 attempts is:
    // 1 - (1 - 1/oddsPerBlock)^144
    const probabilityPerBlock = 1 / oddsPerBlock;
    const probabilityPerDay = 1 - Math.pow(1 - probabilityPerBlock, 144);
    const oddsPerDay = Math.round(1 / probabilityPerDay);
    
    return {
      perBlock: Math.round(oddsPerBlock),
      perDay: oddsPerDay
    };
  } catch (error) {
    console.error('Error calculating solo mining odds:', error);
    return {
      perBlock: Infinity,
      perDay: Infinity
    };
  }
}

// Calculate BTC mined per day based on hashrate and difficulty
export function calculateBTCPerDay(
  hashrate: number,
  hashRateUnit: keyof typeof HASH_RATE_MULTIPLIERS,
  difficulty: number,
  timestamp: number,
  numberOfMiners: number = 1
): number {
  try {
    // Input validation with safe defaults
    if (!hashrate || isNaN(hashrate) || hashrate <= 0) {
      console.warn('Invalid hashrate, using 1 TH/s as default');
      hashrate = 1;
      hashRateUnit = 'TH/s';
    }
    
    if (!HASH_RATE_MULTIPLIERS[hashRateUnit]) {
      console.warn('Invalid hash rate unit, using TH/s as default');
      hashRateUnit = 'TH/s';
    }
    
    if (!difficulty || isNaN(difficulty) || difficulty <= 0) {
      console.warn('Invalid network difficulty, using minimum difficulty');
      difficulty = 1;
    }
    
    // Convert to TH/s first, accounting for number of miners
    const hashrateInTHs = hashrate * HASH_RATE_MULTIPLIERS[hashRateUnit] * numberOfMiners;
    
    // Convert TH/s to H/s
    const hashrateHs = hashrateInTHs * 1e12;
    
    // Calculate blocks per day based on your hashrate share
    // Formula: (hashrate_in_H/s * seconds_per_day) / (difficulty * 2^32)
    const blocksPerDay = (hashrateHs * 86400) / (difficulty * Math.pow(2, 32));
    
    // Get the block reward for the given timestamp
    const blockReward = getBlockReward(timestamp);
    
    // Calculate BTC per day
    const btcPerDay = blocksPerDay * blockReward;
    
    // Ensure the result is a valid number
    if (isNaN(btcPerDay) || !isFinite(btcPerDay)) {
      console.warn('Invalid BTC per day calculation result, using 0 as fallback');
      return 0;
    }
    
    return btcPerDay;
  } catch (error) {
    console.error('Error in calculateBTCPerDay:', error);
    return 0; // Return 0 as a safe fallback
  }
}

// Calculate per TH/s metrics
export function calculateEfficiencyMetrics(result: any, inputs: any) {
  try {
    // Convert hashrate to TH/s for consistent calculations
    const hashrateInTHs = inputs.hashRate * HASH_RATE_MULTIPLIERS[inputs.hashRateUnit];
    
    if (hashrateInTHs <= 0) {
      console.error('Invalid hashrate conversion:', hashrateInTHs);
      return null;
    }

    // Calculate daily BTC per TH/s directly using the calculateBTCPerDay function
    const dailyBTCPerTHs = calculateBTCPerDay(1, 'TH/s', result.difficulty, result.timestamp);
    
    // Calculate other metrics based on the correct BTC per TH/s value
    const dailyRevenuePerTHs = dailyBTCPerTHs * result.price;
    const dailyPowerCostPerTHs = (inputs.powerConsumption / hashrateInTHs * 24 * inputs.electricityCost) / 1000;
    const dailyPoolFeesPerTHs = dailyRevenuePerTHs * (inputs.poolFee / 100);
    
    // Calculate net profit
    const dailyNetProfitPerTHs = dailyRevenuePerTHs - dailyPowerCostPerTHs - dailyPoolFeesPerTHs;
    const dailyNetProfitPerTHsInBTC = dailyBTCPerTHs - (dailyPowerCostPerTHs / result.price) - (dailyPoolFeesPerTHs / result.price);

    return {
      powerEfficiency: inputs.powerConsumption / hashrateInTHs,
      dailyPowerCostPerTHs,
      dailyBTCPerTHs,
      dailyRevenuePerTHs,
      dailyPoolFeesPerTHs,
      dailyPoolFeesPerTHsInBTC: dailyPoolFeesPerTHs / result.price,
      dailyElectricityCostPerTHsInBTC: dailyPowerCostPerTHs / result.price,
      dailyNetProfitPerTHs,
      dailyNetProfitPerTHsInBTC
    };
  } catch (error) {
    console.error('Error calculating efficiency metrics:', error);
    return null;
  }
}