import React from 'react';
import { Scale } from 'lucide-react';

export default function Terms() {
  return (
    <main className="flex-grow matrix-bg py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto matrix-card p-8 rounded-lg">
        <div className="flex items-center gap-2 mb-8">
          <Scale className="w-8 h-8 text-matrix-green" />
          <h1 className="text-3xl font-bold matrix-text">Terms of Service</h1>
        </div>

        <div className="space-y-6 matrix-text">
          <section>
            <h2 className="text-xl font-semibold mb-3">1. Acceptance of Terms</h2>
            <p>
              By accessing and using this Bitcoin Mining Calculator, you accept and agree to be bound by 
              these Terms of Service. If you do not agree to these terms, you must not use this calculator.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">2. Disclaimer of Liability</h2>
            <p>
              This calculator is provided "as is" without any warranties of any kind. You acknowledge and 
              agree that:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>All calculations are estimates and not guaranteed to be accurate</li>
              <li>Mining profitability can change dramatically due to market conditions</li>
              <li>We are not responsible for any financial losses incurred</li>
              <li>You are solely responsible for your mining decisions</li>
              <li>Past performance does not guarantee future results</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">3. No Financial Advice</h2>
            <p>
              The information provided by this calculator does not constitute financial, investment, or 
              mining advice. All decisions regarding cryptocurrency mining should be made after conducting 
              your own research and consulting with qualified professionals.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">4. Feature Requests and Donations</h2>
            <p>
              By submitting feature requests or donations through Bitcoin transactions, you agree that:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>All transactions are final and non-refundable</li>
              <li>Feature requests are not guaranteed to be implemented</li>
              <li>Higher value transactions may receive priority consideration</li>
              <li>Messages sent via OP_RETURN are public and permanent</li>
              <li>You are responsible for the accuracy of transaction data</li>
              <li>We are not liable for lost or misdirected transactions</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">5. Advertising</h2>
            <p>
              This site displays cryptocurrency-related advertisements through A-ADS. You acknowledge that:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Ads are provided by third parties and paid in Bitcoin</li>
              <li>We do not endorse any advertised products or services</li>
              <li>You are responsible for evaluating any advertised offerings</li>
              <li>Ad content may vary based on your location and other factors</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">6. Data Accuracy</h2>
            <p>
              While we strive to provide accurate data, you acknowledge that:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Network difficulty and price data may be delayed or inaccurate</li>
              <li>Actual mining results may vary significantly from calculations</li>
              <li>Energy costs and mining efficiency can fluctuate</li>
              <li>Network conditions can change rapidly</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">7. User Responsibilities</h2>
            <p>
              You are responsible for:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Verifying all calculations independently</li>
              <li>Understanding the risks of cryptocurrency mining</li>
              <li>Maintaining appropriate security measures</li>
              <li>Complying with local laws and regulations</li>
              <li>Managing your own mining operations</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">8. Modifications</h2>
            <p>
              We reserve the right to modify these terms at any time without notice. Continued use of 
              the calculator constitutes acceptance of any changes.
            </p>
          </section>

          <div className="mt-8 pt-6 border-t border-matrix-green/20">
            <p className="text-sm matrix-text opacity-80">
              Last updated: {new Date().toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}