import { ChartOptions } from 'chart.js';

export const chartOptions: Record<string, ChartOptions<'line'>> = {
  btc: {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(0, 20, 0, 0.8)',
        borderColor: '#00ff00',
        borderWidth: 1,
        titleColor: '#00ff00',
        bodyColor: '#00ff00',
        titleFont: {
          family: '"Matrix Code NFI", monospace'
        },
        bodyFont: {
          family: '"Matrix Code NFI", monospace'
        },
        callbacks: {
          label: (context: any) => `${context.dataset.label}: ${context.raw.toFixed(8)} BTC`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 255, 0, 0.1)'
        },
        ticks: {
          color: '#00ff00'
        }
      },
      x: {
        grid: {
          color: 'rgba(0, 255, 0, 0.1)'
        },
        ticks: {
          color: '#00ff00',
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 12
        }
      }
    }
  },
  money: {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(0, 20, 0, 0.8)',
        borderColor: '#00ff00',
        borderWidth: 1,
        titleColor: '#00ff00',
        bodyColor: '#00ff00',
        titleFont: {
          family: '"Matrix Code NFI", monospace'
        },
        bodyFont: {
          family: '"Matrix Code NFI", monospace'
        },
        callbacks: {
          label: (context: any) => `${context.dataset.label}: $${context.raw.toFixed(2)}`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 255, 0, 0.1)'
        },
        ticks: {
          color: '#00ff00',
          callback: (value: any) => `$${value}`
        }
      },
      x: {
        grid: {
          color: 'rgba(0, 255, 0, 0.1)'
        },
        ticks: {
          color: '#00ff00',
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 12
        }
      }
    }
  },
  difficulty: {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(0, 20, 0, 0.8)',
        borderColor: '#00ff00',
        borderWidth: 1,
        titleColor: '#00ff00',
        bodyColor: '#00ff00',
        titleFont: {
          family: '"Matrix Code NFI", monospace'
        },
        bodyFont: {
          family: '"Matrix Code NFI", monospace'
        },
        callbacks: {
          label: (context: any) => `${context.dataset.label}: ${(context.raw / 1e12).toFixed(2)}T`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 255, 0, 0.1)'
        },
        ticks: {
          color: '#00ff00',
          callback: (value: any) => `${(value / 1e12).toFixed(1)}T`
        }
      },
      x: {
        grid: {
          color: 'rgba(0, 255, 0, 0.1)'
        },
        ticks: {
          color: '#00ff00',
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 12
        }
      }
    }
  },
  timeToMine: {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(0, 20, 0, 0.8)',
        borderColor: '#00ff00',
        borderWidth: 1,
        titleColor: '#00ff00',
        bodyColor: '#00ff00',
        titleFont: {
          family: '"Matrix Code NFI", monospace'
        },
        bodyFont: {
          family: '"Matrix Code NFI", monospace'
        },
        callbacks: {
          label: (context: any) => {
            const days = context.raw;
            const years = (days / 365).toFixed(2);
            return `${context.dataset.label}: ${days.toFixed(1)} days (${years} years)`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 255, 0, 0.1)'
        },
        ticks: {
          color: '#00ff00',
          callback: (value: any) => {
            if (value >= 365) {
              return `${(value / 365).toFixed(1)}y`;
            }
            return `${value.toFixed(0)}d`;
          }
        }
      },
      x: {
        grid: {
          color: 'rgba(0, 255, 0, 0.1)'
        },
        ticks: {
          color: '#00ff00',
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 12
        }
      }
    }
  }
};

export function createChartData(
  labels: string[],
  data: number[],
  label: string,
  color: string,
  segmented = false
) {
  return {
    labels,
    datasets: [{
      label,
      data,
      borderColor: segmented ? undefined : color,
      backgroundColor: segmented ? undefined : `${color}33`,
      segment: segmented ? {
        borderColor: (ctx: any) => {
          const value = ctx.p0.parsed.y;
          return value >= 0 ? '#00ff00' : '#ff073a';
        },
        backgroundColor: (ctx: any) => {
          const value = ctx.p0.parsed.y;
          return value >= 0 ? 'rgba(0, 255, 0, 0.2)' : 'rgba(255, 7, 58, 0.2)';
        }
      } : undefined,
      fill: 'origin'
    }]
  };
}