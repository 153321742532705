import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { X, Heart } from 'lucide-react';

interface DonationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDownload: () => void;
}

export default function DonationModal({ isOpen, onClose, onDownload }: DonationModalProps) {
  if (!isOpen) return null;

  const btcAddress = "bc1qffcan0z6xfv0nud7q5cdsl7uuhjdeqrz0dgxf0";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="matrix-card p-6 rounded-lg max-w-md w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-matrix-green hover:text-matrix-lightGreen transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center space-y-4">
          <div className="flex items-center justify-center gap-2">
            <Heart className="w-6 h-6 text-matrix-green" />
            <h2 className="text-xl font-bold matrix-text">Support the Developer</h2>
          </div>

          <p className="matrix-text text-sm">
            If you find this tool useful, consider supporting its development with a Bitcoin donation.
            Your contribution helps maintain and improve the calculator.
          </p>

          <div className="bg-white p-4 rounded-lg inline-block">
            <QRCodeSVG
              value={`bitcoin:${btcAddress}`}
              size={200}
              level="H"
              includeMargin={true}
              className="mx-auto"
            />
          </div>

          <div className="space-y-2">
            <p className="matrix-text text-sm font-mono break-all">
              {btcAddress}
            </p>
            <button
              onClick={() => {
                navigator.clipboard.writeText(btcAddress);
                alert('BTC address copied to clipboard!');
              }}
              className="matrix-button text-sm px-3 py-1 rounded"
            >
              Copy Address
            </button>
          </div>

          <div className="pt-4 space-y-2">
            <button
              onClick={() => {
                onDownload();
                onClose();
              }}
              className="matrix-button w-full py-2 rounded-lg font-medium"
            >
              Download Excel File
            </button>
            <button
              onClick={onClose}
              className="matrix-text text-sm hover:text-matrix-lightGreen transition-colors"
            >
              Maybe Later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}