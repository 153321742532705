import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { X, Copy, MessageSquare } from 'lucide-react';

interface BTCModalProps {
  isOpen: boolean;
  onClose: () => void;
  address: string;
  type: 'feature' | 'support';
}

export default function BTCModal({ isOpen, onClose, address, type }: BTCModalProps) {
  if (!isOpen) return null;

  const handleCopy = () => {
    navigator.clipboard.writeText(address);
    alert('BTC address copied to clipboard!');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="matrix-card p-6 rounded-lg max-w-md w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-matrix-green hover:text-matrix-lightGreen transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center space-y-4">
          {type === 'feature' ? (
            <>
              <div className="flex items-center justify-center gap-2">
                <MessageSquare className="w-6 h-6 text-matrix-green" />
                <h2 className="text-xl font-bold matrix-text">Send Feature Request</h2>
              </div>
              <div className="space-y-4 text-sm matrix-text">
                <p>To submit a feature request:</p>
                <ol className="text-left list-decimal pl-5 space-y-2">
                  <li>Use a wallet that supports OP_RETURN data</li>
                  <li>Send any amount of BTC (higher amounts = higher priority)</li>
                  <li>Include your feature request in the transaction's OP_RETURN data</li>
                  <li>Keep the message under 80 bytes</li>
                </ol>
                <p className="text-xs opacity-80 mt-4">
                  Popular wallets supporting OP_RETURN: Electrum, Bitcoin Core, BlueWallet
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-center gap-2">
                <MessageSquare className="w-6 h-6 text-matrix-green" />
                <h2 className="text-xl font-bold matrix-text">Support Development</h2>
              </div>
              <p className="matrix-text text-sm">
                Your support helps maintain and improve the calculator. Thank you!
              </p>
            </>
          )}

          <div className="bg-white p-4 rounded-lg inline-block">
            <QRCodeSVG
              value={`bitcoin:${address}`}
              size={200}
              level="H"
              includeMargin={true}
              className="mx-auto"
            />
          </div>

          <div className="space-y-2">
            <p className="matrix-text text-sm font-mono break-all">
              {address}
            </p>
            <button
              onClick={handleCopy}
              className="matrix-button text-sm px-3 py-1 rounded inline-flex items-center gap-1"
            >
              <Copy className="w-4 h-4" />
              Copy Address
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}