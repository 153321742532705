import React, { useState } from 'react';
import { Bitcoin, AlertCircle, Download, Lock, Shield, TrendingUp, Zap, DollarSign, Wallet, Hash } from 'lucide-react';
import Header from './components/Header';
import Footer from './components/Footer';
import InputForm from './components/InputForm';
import ResultsChart from './components/ResultsChart';
import DonationModal from './components/DonationModal';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import AdBanner from './components/AdBanner';
import { MiningInputs, MiningResults, NetworkData, HASH_RATE_MULTIPLIERS } from './types/mining';
import { fetchHistoricalData, cacheData, getCachedData, ApiError, getCurrentBTCPrice } from './utils/api';
import { calculateBTCPerDay, calculateSoloMiningOdds } from './utils/mining';
import { exportToExcel } from './utils/excel';

const AD_SLOT = '2382090';

// Helper function to format sats
const formatSats = (btc: number) => (btc * 1e8).toLocaleString(undefined, { maximumFractionDigits: 0 });

// Helper function to get block reward based on timestamp
const getBlockReward = (timestamp: number) => {
  const HALVING_INTERVAL = 210000;
  const INITIAL_REWARD = 50;
  const GENESIS_TIMESTAMP = 1231006505000;
  const AVERAGE_BLOCK_TIME = 600000; // 10 minutes in milliseconds

  const blocksSinceGenesis = Math.floor((timestamp - GENESIS_TIMESTAMP) / AVERAGE_BLOCK_TIME);
  const halvings = Math.floor(blocksSinceGenesis / HALVING_INTERVAL);
  return INITIAL_REWARD / Math.pow(2, halvings);
};

function App() {
  const [currentPage, setCurrentPage] = useState<'calculator' | 'privacy' | 'terms'>('calculator');
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<MiningResults[]>([]);
  const [networkData, setNetworkData] = useState<NetworkData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentBTCPrice, setCurrentBTCPrice] = useState<number | null>(null);
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [currentInputs, setCurrentInputs] = useState<MiningInputs | null>(null);

  const pageTitles = {
    calculator: 'Bitcoin Mining Calculator | BTC Mining ROI & Profitability Analysis',
    privacy: 'Privacy Policy | Bitcoin Mining Calculator',
    terms: 'Terms of Service | Bitcoin Mining Calculator'
  };

  React.useEffect(() => {
    document.title = pageTitles[currentPage];
  }, [currentPage]);

  // Calculate current BTC per TH/s per day
  const btcPerTHsPerDay = React.useMemo(() => {
    if (networkData.length === 0) return null;
    
    const latestData = networkData[networkData.length - 1];
    // Use calculateBTCPerDay with 1 TH/s to get the per TH/s rate
    return calculateBTCPerDay(1, 'TH/s', latestData.difficulty, latestData.timestamp);
  }, [networkData]);

  const calculateMiningResults = (inputs: MiningInputs, networkData: NetworkData[]): MiningResults[] => {
    try {
      let cumulativeProfit = -inputs.equipmentCost * inputs.numberOfMiners;
      let roiDay: number | null = null;

      return networkData.map((data, index) => {
        try {
          const btcPerDay = calculateBTCPerDay(
            inputs.hashRate,
            inputs.hashRateUnit,
            data.difficulty,
            data.timestamp,
            inputs.numberOfMiners
          );

          const soloMiningOdds = calculateSoloMiningOdds(
            inputs.hashRate,
            inputs.hashRateUnit,
            data.difficulty,
            inputs.numberOfMiners
          );

          const dailyPowerConsumption = (inputs.powerConsumption * inputs.numberOfMiners / 1000) * 24;

          const revenue = btcPerDay * data.price;
          const electricityCost = dailyPowerConsumption * inputs.electricityCost;
          const poolFees = revenue * (inputs.poolFee / 100);
          const poolFeesInBTC = poolFees / data.price;
          const electricityCostInBTC = electricityCost / data.price;
          const netProfit = revenue - electricityCost - poolFees;
          const netProfitInBTC = btcPerDay - electricityCostInBTC - poolFeesInBTC;

          cumulativeProfit += netProfit;

          if (roiDay === null && cumulativeProfit >= 0) {
            roiDay = index;
          }

          if (isNaN(revenue) || isNaN(electricityCost) || isNaN(poolFees) || isNaN(netProfit)) {
            throw new Error('Invalid calculation results');
          }

          return {
            btcMined: btcPerDay,
            revenue,
            electricityCost,
            electricityCostInBTC,
            poolFees,
            poolFeesInBTC,
            netProfit,
            netProfitInBTC,
            timestamp: data.timestamp,
            daysToMineBTC: 1 / btcPerDay,
            soloMiningOdds,
            cumulativeProfit,
            daysToROI: roiDay
          };
        } catch (error) {
          console.error('Error calculating mining results for timestamp:', data.timestamp, error);
          throw new Error(
            `Failed to calculate mining results for ${new Date(data.timestamp).toLocaleDateString()}`
          );
        }
      });
    } catch (error) {
      console.error('Error calculating mining results:', error);
      throw new Error(
        `Failed to calculate mining results: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  };

  const calculateEfficiencyMetrics = (result: MiningResults, inputs: MiningInputs) => {
    // Convert hashrate to TH/s for consistent calculations
    const hashRateInTHs = inputs.hashRate * HASH_RATE_MULTIPLIERS[inputs.hashRateUnit];
    
    if (hashRateInTHs <= 0) {
      console.error('Invalid hashrate conversion:', hashRateInTHs);
      return null;
    }
    
    // Get the latest network data
    const latestNetworkData = networkData[networkData.length - 1];
    if (!latestNetworkData) return null;

    // Calculate daily BTC per TH/s using the same function as the top stats
    const dailyBTCPerTHs = calculateBTCPerDay(1, 'TH/s', latestNetworkData.difficulty, latestNetworkData.timestamp);
    
    // Power efficiency (W/TH/s)
    const powerEfficiency = inputs.powerConsumption / hashRateInTHs;
    
    // Daily electricity cost per TH/s
    const dailyPowerCostPerTHs = (powerEfficiency * 24 * inputs.electricityCost) / 1000; // kWh conversion
    
    // Daily revenue per TH/s
    const dailyRevenuePerTHs = dailyBTCPerTHs * latestNetworkData.price;
    
    // Pool fees per TH/s
    const dailyPoolFeesPerTHs = dailyRevenuePerTHs * (inputs.poolFee / 100);
    const dailyPoolFeesPerTHsInBTC = dailyPoolFeesPerTHs / latestNetworkData.price;
    const dailyElectricityCostPerTHsInBTC = dailyPowerCostPerTHs / latestNetworkData.price;
    
    // Net profit calculations
    const dailyNetProfitPerTHs = dailyRevenuePerTHs - dailyPowerCostPerTHs - dailyPoolFeesPerTHs;
    const dailyNetProfitPerTHsInBTC = dailyBTCPerTHs - dailyElectricityCostPerTHsInBTC - dailyPoolFeesPerTHsInBTC;

    // Validate calculations
    const metrics = {
      powerEfficiency,
      dailyPowerCostPerTHs,
      dailyBTCPerTHs,
      dailyRevenuePerTHs,
      dailyPoolFeesPerTHs,
      dailyPoolFeesPerTHsInBTC,
      dailyElectricityCostPerTHsInBTC,
      dailyNetProfitPerTHs,
      dailyNetProfitPerTHsInBTC
    };

    // Verify no NaN or infinite values
    for (const [key, value] of Object.entries(metrics)) {
      if (isNaN(value) || !isFinite(value)) {
        console.error(`Invalid calculation for ${key}:`, value);
        return null;
      }
    }

    return metrics;
  };

  const handleSubmit = async (inputs: MiningInputs) => {
    setIsLoading(true);
    setError(null);
    setCurrentInputs(inputs);

    try {
      if (!currentBTCPrice) {
        const price = await getCurrentBTCPrice();
        setCurrentBTCPrice(price);
      }

      const cacheKey = `mining-data-${inputs.startDate.getTime()}-${inputs.endDate.getTime()}`;
      let data = getCachedData(cacheKey);

      if (!data) {
        data = await fetchHistoricalData(inputs.startDate, inputs.endDate);
        cacheData(cacheKey, data);
      }

      setNetworkData(data);
      const results = calculateMiningResults(inputs, data);
      setResults(results);
    } catch (error) {
      console.error('Error calculating mining results:', error);
      setError(
        error instanceof ApiError
          ? error.message
          : 'An unexpected error occurred. Please try again.'
      );
      setResults([]);
      setNetworkData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = () => {
    if (results.length === 0 || networkData.length === 0) {
      setError('No data to export. Please calculate mining results first.');
      return;
    }
    setShowDonationModal(true);
  };

  const downloadExcel = () => {
    try {
      exportToExcel({ results, networkData });
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      setError('Failed to export data. Please try again.');
    }
  };

  const formatMetric = (value: number, unit: string, decimals: number = 8) => {
    if (unit === 'sats') {
      return `${formatSats(value)} ${unit}`;
    }
    return `${value.toFixed(decimals)} ${unit}`;
  };

  const renderContent = () => {
    if (currentPage === 'privacy') {
      return <Privacy />;
    }

    if (currentPage === 'terms') {
      return <Terms />;
    }

    const totalBTCMined = results.reduce((sum, r) => sum + r.btcMined, 0);
    const totalRevenue = results.reduce((sum, r) => sum + r.revenue, 0);
    const totalElectricityCost = results.reduce((sum, r) => sum + r.electricityCost, 0);
    const totalElectricityCostBTC = results.reduce((sum, r) => sum + r.electricityCostInBTC, 0);
    const totalPoolFees = results.reduce((sum, r) => sum + r.poolFees, 0);
    const totalPoolFeesBTC = results.reduce((sum, r) => sum + r.poolFeesInBTC, 0);
    const totalNetProfit = results.reduce((sum, r) => sum + r.netProfit, 0);
    const totalNetProfitBTC = results.reduce((sum, r) => sum + r.netProfitInBTC, 0);
    const latestResult = results[results.length - 1];

    return (
      <main className="flex-grow py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold matrix-title text-center mb-4">
            Bitcoin Mining Calculator
          </h1>

          <div className="text-center mb-8">
            <div className="flex items-center justify-center gap-4 text-sm matrix-text opacity-80">
              <div className="flex items-center gap-1">
                <Lock className="w-4 h-4" />
                <span>Secure Local Calculations</span>
              </div>
              <div className="flex items-center gap-1">
                <Shield className="w-4 h-4" />
                <span>Real-Time Network Data</span>
              </div>
            </div>
            <div className="mt-4 flex flex-col sm:flex-row items-center justify-center gap-4">
              {currentBTCPrice && (
                <p className="text-lg matrix-text">
                  Current BTC Price: <span className="matrix-value">${currentBTCPrice.toLocaleString()}</span>
                </p>
              )}
              {btcPerTHsPerDay && (
                <div className="flex items-center gap-2 text-lg matrix-text">
                  <Hash className="w-5 h-5 text-matrix-green" />
                  <span>1 TH/s = </span>
                  <span className="matrix-value">{btcPerTHsPerDay.toFixed(8)} BTC</span>
                  <span className="text-sm opacity-80">({formatSats(btcPerTHsPerDay)} sats)</span>
                </div>
              )}
            </div>
          </div>

          <div className="space-y-8">
            {error && (
              <div className="matrix-card p-4 rounded-md border-red-500">
                <div className="flex items-center gap-2">
                  <AlertCircle className="w-5 h-5 text-red-400" />
                  <p className="text-red-400">{error}</p>
                </div>
              </div>
            )}

            <InputForm onSubmit={handleSubmit} isLoading={isLoading} />
            
            {results.length > 0 && networkData.length > 0 && (
              <div className="space-y-6">
                <div className="flex justify-end">
                  <button
                    onClick={handleExport}
                    className="matrix-button px-4 py-2 rounded-lg flex items-center gap-2"
                  >
                    <Download className="w-5 h-5" />
                    Export to Excel
                  </button>
                </div>

                <ResultsChart results={results} networkData={networkData} />

                {latestResult && (
                  <div className="matrix-card p-6 rounded-lg">
                    <h3 className="text-xl font-bold matrix-text mb-4 flex items-center gap-2">
                      <TrendingUp className="w-6 h-6 text-matrix-green" />
                      Current Mining Statistics
                    </h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <Bitcoin className="w-5 h-5 text-matrix-green" />
                          <h4 className="text-lg font-medium matrix-text">Daily BTC</h4>
                        </div>
                        <p className="text-2xl font-bold matrix-value">
                          {latestResult.btcMined.toFixed(8)} BTC
                        </p>
                        <p className="text-sm matrix-text opacity-80">
                          {formatSats(latestResult.btcMined)} sats
                        </p>
                        <div className="space-y-1">
                          <p className="text-sm matrix-text opacity-80">
                            Solo Mining Stats:
                          </p>
                          <div className="space-y-1 border-l-2 border-matrix-green/20 pl-2">
                            <p className="text-sm matrix-text">
                              Current Block Reward:
                            </p>
                            <div className="pl-2 space-y-1">
                              <p className="text-sm matrix-value">
                                {getBlockReward(latestResult.timestamp)} BTC
                              </p>
                              <p className="text-xs matrix-text opacity-80">
                                {formatSats(getBlockReward(latestResult.timestamp))} sats
                              </p>
                              <p className="text-xs matrix-text opacity-80">
                                ${(getBlockReward(latestResult.timestamp) * networkData[networkData.length - 1].price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </p>
                            </div>
                          </div>
                          <p className="text-sm matrix-text opacity-80">
                            • 1 in {latestResult.soloMiningOdds.perBlock.toLocaleString()} chance per block
                          </p>
                          <p className="text-sm matrix-text opacity-80">
                            • 1 in {latestResult.soloMiningOdds.perDay.toLocaleString()} chance to find a block today
                          </p>
                        </div>
                      </div>
                      
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <DollarSign className="w-5 h-5 text-matrix-green" />
                          <h4 className="text-lg font-medium matrix-text">Daily Revenue</h4>
                        </div>
                        <p className="text-2xl font-bold matrix-value">
                          ${latestResult.revenue.toFixed(2)}
                        </p>
                        <p className="text-sm matrix-text opacity-80">
                          {latestResult.btcMined.toFixed(8)} BTC
                          <span className="opacity-70"> ({formatSats(latestResult.btcMined)} sats)</span>
                        </p>
                      </div>
                      
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <Zap className="w-5 h-5 text-matrix-green" />
                          <h4 className="text-lg font-medium matrix-text">Daily Costs</h4>
                        </div>
                        <div>
                          <p className="text-2xl font-bold matrix-value">
                            ${(latestResult.electricityCost + latestResult.poolFees).toFixed(2)}
                          </p>
                          <p className="text-sm matrix-text opacity-80">
                            {(latestResult.electricityCostInBTC + latestResult.poolFeesInBTC).toFixed(8)} BTC
                            <span className="opacity-70"> ({formatSats(latestResult.electricityCostInBTC + latestResult.poolFeesInBTC)} sats)</span>
                          </p>
                          <div className="mt-1 text-sm matrix-text opacity-80">
                            <p>Power: ${latestResult.electricityCost.toFixed(2)} ({latestResult.electricityCostInBTC.toFixed(8)} BTC / {formatSats(latestResult.electricityCostInBTC)} sats)</p>
                            <p>Pool Fees: ${latestResult.poolFees.toFixed(2)} ({latestResult.poolFeesInBTC.toFixed(8)} BTC / {formatSats(latestResult.poolFeesInBTC)} sats)</p>
                          </div>
                        </div>
                      </div>
                      
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <Wallet className="w-5 h-5 text-matrix-green" />
                          <h4 className="text-lg font-medium matrix-text">Daily Profit</h4>
                        </div>
                        <p className="text-2xl font-bold matrix-value">
                          ${latestResult.netProfit.toFixed(2)}
                        </p>
                        <p className="text-sm matrix-text opacity-80">
                          {latestResult.netProfitInBTC.toFixed(8)} BTC
                          <span className="opacity-70"> ({formatSats(latestResult.netProfitInBTC)} sats)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                  <div className="matrix-card p-6 rounded-lg">
                    <h4 className="text-lg font-medium matrix-text mb-2">Total BTC Mined</h4>
                    <p className="text-3xl font-bold matrix-value">
                      {totalBTCMined.toFixed(8)} BTC
                    </p>
                    <p className="text-sm matrix-text opacity-80">
                      {formatSats(totalBTCMined)} sats
                    </p>
                    {currentBTCPrice && (
                      <p className="text-sm matrix-text mt-2">
                        Current Value: <span className="matrix-value">${(totalBTCMined * currentBTCPrice).toFixed(2)}</span>
                      </p>
                    )}
                  </div>
                  
                  <div className="matrix-card p-6 rounded-lg">
                    <h4 className="text-lg font-medium matrix-text mb-2">Total Revenue</h4>
                    <p className="text-3xl font-bold matrix-value">
                      ${totalRevenue.toFixed(2)}
                    </p>
                    <p className="text-sm matrix-text opacity-80">
                      {totalBTCMined.toFixed(8)} BTC
                      <span className="opacity-70"> ({formatSats(totalBTCMined)} sats)</span>
                    </p>
                  </div>

                  <div className="matrix-card p-6 rounded-lg">
                    <h4 className="text-lg font-medium matrix-text mb-2">Total Costs</h4>
                    <p className="text-3xl font-bold matrix-value">
                      ${(totalElectricityCost + totalPoolFees).toFixed(2)}
                    </p>
                    <p className="text-sm matrix-text opacity-80">
                      {(totalElectricityCostBTC + totalPoolFeesBTC).toFixed(8)} BTC
                      <span className="opacity-70"> ({formatSats(totalElectricityCostBTC + totalPoolFeesBTC)} sats)</span>
                    </p>
                    <div className="mt-2 text-sm matrix-text opacity-80">
                      <p>Power: ${totalElectricityCost.toFixed(2)} ({totalElectricityCostBTC.toFixed(8)} BTC / {formatSats(totalElectricityCostBTC)} sats)</p>
                      <p>Pool Fees: ${totalPoolFees.toFixed(2)} ({totalPoolFeesBTC.toFixed(8)} BTC / {formatSats(totalPoolFeesBTC)} sats)</p>
                    </div>
                  </div>
                  
                  <div className="matrix-card p-6 rounded-lg">
                    <h4 className="text-lg font-medium matrix-text mb-2">Total Net Profit</h4>
                    <p className="text-3xl font-bold matrix-value">
                      ${totalNetProfit.toFixed(2)}
                    </p>
                    <p className="text-sm matrix-text opacity-80">
                      {totalNetProfitBTC.toFixed(8)} BTC
                      <span className="opacity-70"> ({formatSats(totalNetProfitBTC)} sats)</span>
                    </p>
                  </div>

                  {/* Mining Efficiency Stats */}
                  {currentInputs && results[0] && (
                    <div className="matrix-card p-6 rounded-lg md:col-span-4">
                      <div className="flex items-center justify-between mb-4">
                        <h4 className="text-lg font-medium matrix-text">
                          Mining Efficiency (All metrics calculated per TH/s)
                        </h4>
                      </div>

                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        {(() => {
                          const metrics = calculateEfficiencyMetrics(results[0], currentInputs);
                          
                          if (!metrics) {
                            return (
                              <div className="col-span-2 text-center matrix-text">
                                Error calculating mining efficiency metrics
                              </div>
                            );
                          }
                          
                          return (
                            <>
                              {/* Power and Cost Metrics */}
                              <div className="space-y-4">
                                <div className="p-4 bg-black/30 rounded-lg">
                                  <h5 className="text-sm font-medium matrix-text mb-2">Power Consumption</h5>
                                  <p className="text-2xl font-bold matrix-value">
                                    {metrics.powerEfficiency.toFixed(2)} W/TH/s
                                  </p>
                                </div>

                                <div className="p-4 bg-black/30 rounded-lg">
                                  <h5 className="text-sm font-medium matrix-text mb-2">Daily Costs (USD)</h5>
                                  <div className="space-y-2">
                                    <div>
                                      <p className="text-sm matrix-text opacity-80">Electricity:</p>
                                      <p className="text-xl font-bold matrix-value">
                                        ${metrics.dailyPowerCostPerTHs.toFixed(4)}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="text-sm matrix-text opacity-80">Pool Fees:</p>
                                      <p className="text-lg matrix-value">
                                        ${metrics.dailyPoolFeesPerTHs.toFixed(4)}
                                      </p>
                                    </div>
                                    <div className="pt-2 border-t border-matrix-green/20">
                                      <p className="text-sm matrix-text opacity-80">Total Daily Cost:</p>
                                      <p className="text-xl font-bold matrix-value">
                                        ${(metrics.dailyPowerCostPerTHs + metrics.dailyPoolFeesPerTHs).toFixed(4)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Revenue and Profit Metrics */}
                              <div className="space-y-4">
                                <div className="p-4 bg-black/30 rounded-lg">
                                  <h5 className="text-sm font-medium matrix-text mb-2">Daily Mining Revenue</h5>
                                  <div className="space-y-2">
                                    <div>
                                      <p className="text-2xl font-bold matrix-value">
                                        {metrics.dailyBTCPerTHs.toFixed(8)} BTC
                                      </p>
                                      <p className="text-lg matrix-text opacity-80">
                                        {formatSats(metrics.dailyBTCPerTHs)} sats
                                      </p>
                                    </div>
                                    <div className="pt-2">
                                      <p className="text-xl font-bold matrix-value">
                                        ${metrics.dailyRevenuePerTHs.toFixed(4)} USD
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="p-4 bg-black/30 rounded-lg">
                                  <h5 className="text-sm font-medium matrix-text mb-2">Daily Net Profit</h5>
                                  <div className="space-y-2">
                                    <div>
                                      <p className="text-2xl font-bold matrix-value">
                                        {metrics.dailyNetProfitPerTHsInBTC.toFixed(8)} BTC
                                      </p>
                                      <p className="text-lg matrix-text opacity-80">
                                        {formatSats(metrics.dailyNetProfitPerTHsInBTC)} sats
                                      </p>
                                    </div>
                                    <div className="pt-2">
                                      <p className="text-xl font-bold matrix-value">
                                        ${metrics.dailyNetProfitPerTHs.toFixed(4)} USD
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })()}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    );
  };

  return (
    <div className="min-h-screen matrix-bg flex flex-col">
      <Header onNavigate={setCurrentPage} />
      {renderContent()}
      <div className="mt-auto">
        <AdBanner adSlot={AD_SLOT} className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-8" />
      </div>
      <Footer onNavigate={setCurrentPage} />
      <DonationModal
        isOpen={showDonationModal}
        onClose={() => setShowDonationModal(false)}
        onDownload={downloadExcel}
      />
    </div>
  );
}

export default App;