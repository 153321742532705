import React from 'react';
import { Shield } from 'lucide-react';

export default function Privacy() {
  return (
    <main className="flex-grow matrix-bg py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto matrix-card p-8 rounded-lg">
        <div className="flex items-center gap-2 mb-8">
          <Shield className="w-8 h-8 text-matrix-green" />
          <h1 className="text-3xl font-bold matrix-text">Privacy Policy</h1>
        </div>

        <div className="space-y-6 matrix-text">
          <section>
            <h2 className="text-xl font-semibold mb-3">1. Data Collection</h2>
            <p>
              This Bitcoin Mining Calculator operates entirely in your browser. We do not collect, store, 
              or transmit any personal data. All calculations and analysis are processed locally 
              on your device.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">2. Third-Party Services</h2>
            <p>
              We utilize the following third-party services:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Public APIs to fetch Bitcoin network data and price information</li>
              <li>Anonymous cryptocurrency advertising through A-ADS</li>
            </ul>
            <p className="mt-2">
              These services may use their own tracking methods. However, our implementation ensures:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>No personal data is shared with advertisers</li>
              <li>All ads are cryptocurrency-related and paid in BTC</li>
              <li>Ad tracking is minimal and anonymous</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">3. Local Storage</h2>
            <p>
              Some data may be cached in your browser's local storage to improve performance. This data 
              remains on your device and is not transmitted to any servers. You can clear this data 
              through your browser settings at any time.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">4. Feature Requests and Donations</h2>
            <p>
              Our feature request and donation system operates entirely through the Bitcoin blockchain:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Feature requests are submitted via OP_RETURN data in BTC transactions</li>
              <li>No personal information is required or collected</li>
              <li>All communication is public through the blockchain</li>
              <li>Transaction amounts and messages are visible on the public ledger</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">5. Cookies and Tracking</h2>
            <p>
              This site does not use cookies for tracking or analytics. Any third-party services 
              (such as A-ADS) operate independently and their privacy policies should be consulted 
              separately.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">6. Changes to Privacy Policy</h2>
            <p>
              We reserve the right to update this privacy policy at any time. Continued use of the 
              calculator constitutes acceptance of any changes.
            </p>
          </section>

          <div className="mt-8 pt-6 border-t border-matrix-green/20">
            <p className="text-sm matrix-text opacity-80">
              Last updated: {new Date().toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}